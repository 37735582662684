/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-dialog-container {
  background-color: black !important;
}

.mat-expansion-panel-body {
  padding: 0 0 16px !important;
}

.mat-snack-bar-container {
  background-color: #27272b !important;
  color: white !important;
}

.mat-snack-bar-container .mat-button-wrapper {
  color: #1e8de1 !important;
}
